import Swiper from 'swiper';
import $ from 'jquery';
import { Navigation, Pagination, Autoplay, EffectFade } from 'swiper/modules';
import 'swiper/swiper-bundle.css';

(function ($) {
	$.fn.exists = function () {
		return (this.length > 0);
	};

	$(document).ready(function () {
		console.log('JS and jQuery loaded');
	});
})(jQuery);

window.addEventListener('load', function () {
	footerLinkMobileOpener(),
		tabsColorSwitching(),
		dropdownOpener(),
		openWizard(),
		openClosingWizard(),
		toggleMenu(),
		swiperCards(),
		swiperNews(),
		cardContentShow(),
		cardContentShowWorks(),
		searchBarDesktopOpener(),
		checkboxDropdown(),
		pswShower(),
		openStickyPopup(),
		accordionOpener(),
		accordionfeaturesOpener(),
		swiperModules(),
		swiperServcicesCards(),
		statsLabelHeight(),
		tabsMobileLeftAlign(),

		setTimeout(function () {
			isSafari()
		}, 500);
});

document.addEventListener('DOMContentLoaded', function () {
	/* 	initializeShowMoreButton(); */
	initializeTooltips();
});

$(window).ready(function () {
	var iub = $("a.cookiepref");
	iub.on("click", function (event) {
		try {
			_iub.cs.api.openPreferences()
		} catch (e) { }; return false;
	});
	$(".footer-utils").append("<div class='iubenda-advertising-preferences-link' style='display:none'></div>");
});

function isSafari() {
	if (navigator.userAgent.indexOf("Safari") != -1) {
		if (navigator.userAgent.indexOf('Chrome') < 0) {
			jQuery('.iol-stickybar .iol-radio').addClass('safari-fix');
			jQuery('.iol-checkout-body .iol-radio').addClass('safari-fix');
			jQuery('.iol-content-overlay .iol-radio').addClass('safari-fix');
		}
	}
}

var storiesSlidesCounter = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10'];

const HomepageCarousel = new Swiper('.swiper-container.stories-carousel', {
	modules: [Navigation, Pagination, Autoplay, EffectFade],
	slidesPerView: 1,
	spaceBetween: 30,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
		renderBullet: function (index, className) {
			return '<span class="' + className + '">' + (storiesSlidesCounter[index]) + '</span>';
		},
	},
	breakpoints: {
		768: {
			pagination: {
				clickable: false,
			}
		},
		1024: {
			slidesPerView: 1.5,
		},
		1220: {
			slidesPerView: 1.9,
		},
	}
});

const ContactsCarousel = new Swiper('.swiper-container.contacts-carousel', {
	modules: [Navigation, Pagination, Autoplay, EffectFade],
	slidesPerView: 1,
	spaceBetween: 30,
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
	breakpoints: {
		768: {
			slidesPerView: 3,
			spaceBetween: 0,

		},
	}
});

const BrandsCarousel = new Swiper('.swiper-container.iol-brands-images', {
	modules: [Navigation, Pagination, Autoplay, EffectFade],
	slidesPerView: 2.5,
	spaceBetween: 30,
	autoplay: {
		delay: 2000,
	},
	loop: true,

	breakpoints: {
		768: {
			slidesPerView: 3,
		},
		1024: {
			slidesPerView: 4,
		},
		1200: {
			slidesPerView: 5,
		},
	}
});

var heroCarousel = new Swiper('.swiper-container.hero-slider', {
	modules: [Navigation, Pagination, Autoplay, EffectFade],
	slidesPerView: 1,
	spaceBetween: 30,
	initialSlide: 0,
	autoplay: {
		delay: 2000,
	},
	effect: 'fade',
	loop: true,
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
});

var heroFullCarousel = new Swiper('.iol-fullwidth-hero.swiper-container', {
	modules: [Navigation, Pagination, Autoplay, EffectFade],
	slidesPerView: 1,
	spaceBetween: 0,
	initialSlide: 0,
	autoplay: {
		delay: 5000,
		pauseOnInteraction: true // Mette in pausa l'autoplay quando l'utente interagisce con lo slider
	},
	effect: 'fade',
	loop: true,
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
});

var heroSplitCarousel = new Swiper('.iol-split-hero.swiper-container', {
	modules: [Navigation, Pagination, Autoplay, EffectFade],
	slidesPerView: 1,
	spaceBetween: 0,
	initialSlide: 0,
	autoplay: {
		delay: 5000,
		pauseOnInteraction: true // Mette in pausa l'autoplay quando l'utente interagisce con lo slider
	},
	effect: 'fade',
	loop: true,
	pagination: {
		el: '.swiper-pagination',
		clickable: true,
	},
});

var modulesSlidesCounter = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10'];
function swiperModules() {
	'use strict';
	const breakpoint = window.matchMedia('(min-width:1200px)');
	let mySwiper;

	const breakpointChecker = function () {
		if (breakpoint.matches === true) {
			if (mySwiper !== undefined) mySwiper.destroy(true, true);
			return;
		}
		else if (breakpoint.matches === false) {
			return enableSwiper();
		}
	};

	const enableSwiper = function () {
		var modulesSlidesCounter = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10'];

		mySwiper = new Swiper('.swiper-container.iol-mc-carousel', {
			modules: [Navigation, Pagination, Autoplay, EffectFade],
			slidesPerView: 1,
			spaceBetween: 24,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: false,
				renderBullet: function (index, className) {
					return '<span class="' + className + '">' + (modulesSlidesCounter[index]) + '</span>';
				},
			},
			breakpoints: {
				768: {
					slidesPerView: 2
				},
				1200: {
					slidesPerView: 3
				},
			}
		});
	};

	breakpoint.addEventListener('change', () => {
		if (this) {
			this.breakpointChecker;
		}
	});
	breakpointChecker();
};

function swiperCards() {
	'use strict';
	const breakpoint = window.matchMedia('(min-width:1200px)');
	let mySwiper;

	const breakpointChecker = function () {
		if (breakpoint.matches === true) {
			if (mySwiper !== undefined) mySwiper.destroy(true, true);
			return;
		}
		else if (breakpoint.matches === false) {
			return enableSwiper();
		}
	};

	const enableSwiper = function () {
		mySwiper = new Swiper('.iol-cards.swiper-container', {
			modules: [Navigation, Pagination, Autoplay, EffectFade],
			slidesPerView: 1,
			spaceBetween: 20,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			breakpoints: {
				768: {
					slidesPerView: 1.8
				}
			}
		});
	};

	breakpoint.addEventListener('change', () => {
		if (this) {
			this.breakpointChecker;
		}
	});
	breakpointChecker();
};

function swiperNews() {
	'use strict';
	const breakpoint = window.matchMedia('(min-width:1200px)');
	let mySwiper;

	const breakpointChecker = function () {
		if (breakpoint.matches === true) {
			if (mySwiper !== undefined) mySwiper.destroy(true, true);
			return;
		}
		else if (breakpoint.matches === false) {
			return enableSwiper();
		}
	};

	const enableSwiper = function () {
		mySwiper = new Swiper('.iol-news-container.swiper-container', {
			modules: [Navigation, Pagination, Autoplay, EffectFade],
			slidesPerView: 1,
			spaceBetween: 20,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
			pagination: {
				el: '.swiper-pagination',
				clickable: true,
			},
			breakpoints: {
				768: {
					slidesPerView: 1.8,
					slidesOffsetAfter: 24,
					slidesOffsetBefore: 32,
				},
				1024: {
					slidesPerView: 2.3,
					slidesOffsetAfter: 24,
					slidesOffsetBefore: 32
				},
			}
		});
	};

	breakpoint.addEventListener('change', () => {
		if (this) {
			this.breakpointChecker;
		}
	});
	breakpointChecker();
};

var WorksSlideCarousel = new Swiper('.swiper-container.iol-works', {
	modules: [Navigation, Pagination, Autoplay, EffectFade],
	autoplay: {
		delay: 1000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
	},
	loop: true,
	speed: 2000,
	watchSlidesProgress: true,
	grabCursor: true,
	breakpoints: {
		360: {
			slidesPerView: 1,
			spaceBetween: 0,
		},
		390: {
			slidesPerView: 1,
			spaceBetween: -30,
		},
		768: {
			slidesPerView: 2,
			spaceBetween: 0,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
		1200: {
			slidesPerView: 5,
			spaceBetween: 30,
		},
		1400: {
			slidesPerView: 4,
			spaceBetween: 30,
		},
		1900: {
			slidesPerView: 5,
			spaceBetween: 30,
		},
	}
});

var WorksSlideCarousel = new Swiper('.swiper-container.iol-works-ltr', {
	modules: [Navigation, Pagination, Autoplay, EffectFade],
	autoplay: {
		delay: 1000,
		disableOnInteraction: false,
		pauseOnMouseEnter: true,
		reverseDirection: true,
	},
	loop: true,
	speed: 2000,
	watchSlidesProgress: true,
	grabCursor: true,
	breakpoints: {
		360: {
			slidesPerView: 1,
			spaceBetween: 0,
		},
		390: {
			slidesPerView: 1,
			spaceBetween: -30,
		},

		768: {
			slidesPerView: 2,
			spaceBetween: 0,
		},
		1024: {
			slidesPerView: 3,
			spaceBetween: 20,
		},
		1200: {
			slidesPerView: 5,
			spaceBetween: 30,
		},
		1400: {
			slidesPerView: 4,
			spaceBetween: 30,
		},
		1900: {
			slidesPerView: 5,
			spaceBetween: 30,
		},
	}
});

var smallSlidesCounter = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10'];
var smallCarousel = new Swiper('.swiper-container.small-carousel', {
	modules: [Navigation, Pagination, Autoplay, EffectFade],
	slidesPerView: 1,
	spaceBetween: 30,
	navigation: {
		nextEl: '.swiper-button-next',
		prevEl: '.swiper-button-prev',
	},

	pagination: {
		el: '.swiper-pagination',
		clickable: false,
		//dynamicBullets: smallSlidesCounter.length > 3, 
		renderBullet: function (index, className) {
			return '<span class="' + className + '">' + (smallSlidesCounter[index]) + '</span>';
		},
	},

	breakpoints: {
		768: {
			slidesPerView: 1.4
		},
		1220: {
			slidesPerView: 3,
			// centeredSlides: true,
			// slidesOffsetBefore: -350,
		},
		1400: {
			slidesPerView: 3,
		},
		1920: {
			slidesPerView: 3,
			spaceBetween: 71,
		},
	},
});

function swiperServcicesCards() {
	'use strict';
	var servicesCounter = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10'];
	var services = document.querySelector('.swiper-container.iol-pp-services-cards');
	let breakpoint;

	let mySwiper;

	const breakpointChecker = function () {
		if (breakpoint.matches === true) {
			if (mySwiper !== undefined) mySwiper.destroy(true, true);
			return;
		}
		else if (breakpoint.matches === false) {
			return enableSwiper();
		}
	};

	const enableSwiper = function () {

		if ($(services).hasClass('iol-two-services')) {
			mySwiper = new Swiper(services, {
				modules: [Navigation, Pagination, Autoplay, EffectFade],
				slidesPerView: 1,
				spaceBetween: 20,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: false,
					renderBullet: function (index, className) {
						return '<span class="' + className + '">' + (servicesCounter[index]) + '</span>';
					},
				},
				breakpoints: {
					768: {
						slidesPerView: 2
					}
				},
			});
		}
		else if ($(services).hasClass('iol-three-services') | $(services).hasClass('iol-four-services')) {
			mySwiper = new Swiper(services, {
				modules: [Navigation, Pagination, Autoplay, EffectFade],
				slidesPerView: 1,
				spaceBetween: 20,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true,
					renderBullet: function (index, className) {
						return '<span class="' + className + '">' + (servicesCounter[index]) + '</span>';
					},
				},
				breakpoints: {
					768: {
						slidesPerView: 1.6
					}
				},
			});
		}
	};

	if ($(services).hasClass('iol-two-services')) {
		breakpoint = window.matchMedia('(min-width:767px)');

		breakpoint.addEventListener('change', () => {
			this.breakpointChecker;
		});
		breakpointChecker();
	}

	if ($(services).hasClass('iol-three-services') | $(services).hasClass('iol-four-services')) {
		breakpoint = window.matchMedia('(min-width:1200px)');

		breakpoint.addEventListener('change', () => {
			this.breakpointChecker;
		});
		breakpointChecker();
	}

	// //TO FIX ELEMENT HEIGHT
	setTimeout(function () {
		if (navigator.userAgent.indexOf("Safari") != -1) {
			if (navigator.userAgent.indexOf('Chrome') < 0) {
				var arrayHeight = [];
				$(services).each(function () {
					var fieldTexts = document.querySelectorAll('.swiper-slide .iol-service-card');
					fieldTexts.forEach(function (field, i) {
						arrayHeight[i] = field.clientHeight;
					});
					fieldTexts.forEach(function (field) {
						$(field).css("min-height", Math.max.apply(Math, arrayHeight) + 20);
					});
				});
			}
		}
	}, 500);
};

function footerLinkMobileOpener() {
	var linkLists = document.querySelectorAll('.footer-links');

	if (window.matchMedia("(max-width: 1200px)").matches) {
		linkLists.forEach(item => {
			var linkListsIcon = item.querySelector('.icon-plus');
			var footerLinksTitle = item.querySelector('.footer-links__title ');
			[linkListsIcon, footerLinksTitle].forEach(function (clicked) {
				clicked.addEventListener('click', function () {
					var list = item.querySelector('.footer-links-list');

					if (jQuery(list).hasClass("visible")) {
						list.classList.remove("visible");
						linkListsIcon.classList.remove("visible");
					} else {
						var parent = document.querySelector('.footer-top-links');
						var allActive = parent.querySelectorAll('.visible')

						allActive.forEach(function (a) {
							a.classList.remove('visible');
						});

						list.classList.add("visible");
						linkListsIcon.classList.add("visible");
						item.scrollIntoView();
					}
				});
			});
		});
	}
};

function tabsColorSwitching() {
	var tabs = document.querySelectorAll('.iol-tabs.iol-tabs-home-version');
	var colors = ['#341D5F', '#DE1E63', '#333861', '#21C3F3', '#9BCB3B'];

	if (tabs) {
		tabs.forEach(item => {
			if ($(item).hasClass('iol-tabs-second-version')) {
				$(item).css("background-color", "transparent");
			}
			else {
				var tab = item.querySelectorAll('.nav-link');
				var tabArray = Array.from(tab);

				tab.forEach(elem => {
					elem.addEventListener('click', function () {
						var index = tabArray.indexOf(elem);
						var tabNum = index + 1;
						if ($(elem).is('#tab-' + tabNum + '-tab')) {
							$(item).css("background-color", colors[index]);
						}
					});
				});
			}
		});
	}
};

function tabsMobileLeftAlign() {
	var tabs = document.querySelectorAll('.iol-tabs');

	if (tabs && (window.matchMedia("(max-width: 1219px)").matches)) {
		tabs.forEach(item => {
			var tab = item.querySelectorAll('.nav-link');
			var tabArray = Array.from(tab);
			tab.forEach(elem => {
				elem.addEventListener('click', function () {
					var index = tabArray.indexOf(elem);
					var width = 0;
					for (let i = 0; i < index; i++) {
						width += tabArray[i].offsetWidth;
					}
					if (index === 0) {
						$(tabs).find(".nav-tabs").scrollLeft(width);
					} else {
						$(tabs).find(".nav-tabs").scrollLeft(width + 40);
					}
				});
			});
		});
	}
}

function dropdownOpener() {
	$('.dropdown-el').click(function (e) {
		e.preventDefault();
		e.stopPropagation();
		$(this).toggleClass('expanded');
		$('#' + $(e.target).attr('for')).prop('checked', true);
	});
	$(document).click(function () {
		$('.dropdown-el').removeClass('expanded');
	});
};

function openWizard() {
	var opener = document.querySelector('.openbtn');
	var wizard = document.querySelector("#wizard");

	if (wizard) {
		var closer = wizard.querySelector('.closebtn');

		opener.addEventListener('click', function () {
			wizard.classList.add('open');
			var opened = document.querySelector(".open");

			if (opened) {
				closer.addEventListener('click', function () {
					wizard.classList.remove('open');
				})
			}
		})
	}
};

function openClosingWizard() {
	var closingWizard = document.querySelector("#closing-wizard");
	var closingOpener = document.querySelector('.opensecondbtn');

	if (closingWizard) {
		var closingButton = closingWizard.querySelector('.white-button');

		closingOpener.addEventListener('click', function () {
			closingWizard.classList.add('closing-open');

			var opened = document.querySelector(".closing-open");

			if (opened) {
				closingButton.addEventListener('click', function () {
					closingWizard.classList.remove('closing-open');
				})
			}
		})
	}
};

function cardContentShow() {
	if (window.matchMedia("(max-width: 1200px)").matches) {
		var card = document.querySelectorAll('.iol-card.iol-hover');

		if (card) {
			card.forEach(item => {
				var togglerLabel = item.querySelector('.iol-more-than-one');
				togglerLabel.addEventListener('click', function () {
					item.classList.add('to-show');
					item.classList.remove('not-to-show');

				})

				var closer = item.querySelector('.iol-more-than-one-close');
				if (closer) {
					closer.addEventListener('click', function () {
						item.classList.add('not-to-show');
						item.classList.remove('to-show');
					});
				}
			})
		}
	}

	if (window.matchMedia("(min-width: 1200px)").matches) {
		var card = document.querySelectorAll('.iol-card.iol-hover');

		if (card) {
			card.forEach(item => {
				item.addEventListener('mouseover', function () {
					item.classList.remove('not-to-show');
					item.classList.add('to-show');
				});
				item.addEventListener('mouseout', function () {
					item.classList.remove('to-show');
					item.classList.add('not-to-show');
				});
			})
		}
	}
};

function cardContentShowWorks() {

	if (window.matchMedia("(max-width: 1200px)").matches) {
		var card = document.querySelectorAll('.iol-card-works.iol-hover');

		if (card) {
			card.forEach(item => {
				var togglerLabel = item.querySelector('.iol-more-than-one');
				togglerLabel.addEventListener('click', function () {
					item.classList.add('to-show');
					item.classList.remove('not-to-show');

				})

				var closer = item.querySelector('.iol-more-than-one-close');
				if (closer) {
					closer.addEventListener('click', function () {
						item.classList.add('not-to-show');
						item.classList.remove('to-show');
					});
				}
			})
		}
	}

	if (window.matchMedia("(min-width: 1200px)").matches) {
		var card = document.querySelectorAll('.iol-card-works.iol-hover');

		if (card) {
			card.forEach(item => {
				item.addEventListener('mouseover', function () {
					item.classList.remove('not-to-show');
					item.classList.add('to-show');
				});
				item.addEventListener('mouseout', function () {
					item.classList.remove('to-show');
					item.classList.add('not-to-show');
				});
			})
		}
	}
};

function searchBarDesktopOpener() {
	var searchIcon = document.querySelector('.icon-search');
	var buttonWrapper = document.querySelector('.iol-lateral-button-wrapper');
	var overlay = document.querySelector('.searchbar-overlay');

	if (window.matchMedia("(min-width: 1200px)").matches & searchIcon != undefined) {
		if (!buttonWrapper.classList.contains('open-searchbar')) {
			searchIcon.addEventListener('click', function (e) {
				e.preventDefault();

				var input = buttonWrapper.querySelector('.iol-input');
				var links = document.querySelectorAll('.navbar-link');

				$(buttonWrapper).addClass('open-searchbar');
				$(buttonWrapper).removeClass('close-searchbar');
				$(input).trigger('focus');
				$(overlay).css('display', 'block')
				$('body').css('overflow', 'hidden');

				links.forEach(item => {
					$(item).attr("disabled", true);
					$(item).css('color', '#555555');
				})
				if ($(buttonWrapper).children('.iol-profile') != undefined) {
					$(buttonWrapper).children('.iol-profile').css('display', 'none')
				}
				if ($(buttonWrapper).children('.navbar-button') != undefined) {
					$(buttonWrapper).children('.navbar-button').css('display', 'none')
				}
				if ($('.navbar-brand') != undefined) {
					$('.navbar-brand').css('visibility', 'hidden');
				}
			});

			var iconClose = buttonWrapper.querySelector('.icon-close');
			if (iconClose) {
				iconClose.addEventListener('click', function () {
					$(buttonWrapper).addClass('close-searchbar');
					$(buttonWrapper).removeClass('open-searchbar');
					$(overlay).css('display', 'none');
					$('body').css('overflow', 'auto');

					var links = document.querySelectorAll('.navbar-link');
					links.forEach(item => {
						$(item).attr("disabled", false);
						$(item).css('color', 'white');
					})
					if ($(buttonWrapper).children('.iol-profile') != undefined && $('.iol-lateral-button-wrapper').children('.iol-profile').children('.iol-initials').text() != '') {
						$(buttonWrapper).children('.iol-profile').css('display', 'flex')
					}
					if ($(buttonWrapper).children('.navbar-button') != undefined) {
						$(buttonWrapper).children('.navbar-button').css('display', 'block')
					}
					if ($('.navbar-brand') != undefined) {
						$('.navbar-brand').css('visibility', 'visible');
					}
				});
			}
		}
	}
};

function checkboxDropdown() {
	var $el = $('.iol-request-dropdown');
	function updateStatus(label, result) {
		if (!result.length) {
			label.html("Seleziona ciò di cui hai bisogno*");
		}
	};

	$el.each(function () {
		var $label = $(this).find('.dropdown-label'),
			$checkAll = $(this).find('.check-all'),
			$inputs = $(this).find('.check'),
			$upperLabel = $(this).find('.iol-upper-label'),
			defaultChecked = $(this).find('input[type=checkbox]:checked'),
			result = [];

		updateStatus($label, result);
		if (defaultChecked.length) {
			defaultChecked.each(function () {
				result.push($(this).next().text());
				if (result.length == 1) {
					$label.html(result);
				}
				else {
					$label.html(result.length + ' bisogni elezionati');
				}
			});
		}

		$label.on('click', () => {
			$(this).toggleClass('open');
		});

		$inputs.on('change', function () {
			var checked = $(this).is(':checked');
			var checkedText = $(this).next().text();

			if (checked) {
				result.push(checkedText);
				if (result.length == 1) {
					$label.html(result);
				}
				else {
					$label.html(result.length + ' bisogni elezionati');
				}
				$checkAll.prop('checked', false);
				$upperLabel.addClass('show');
			}
			else {
				let index = result.indexOf(checkedText);
				if (index >= 0) {
					result.splice(index, 1);
				}
				if (!result.length) {
					$upperLabel.removeClass('show');
				}
				if (result.length == 1) {
					$label.html(result);
				}
				else {
					$label.html(result.length + ' bisogni elezionati');
				}
			}
			updateStatus($label, result);
		});

		$(document).on('click touchstart', e => {
			if (!$(e.target).closest($(this)).length) {
				$(this).removeClass('open');
			}
		});
	});
};

function toggleMenu() {
	var menu = document.querySelector(".navbar");

	if (menu) {
		var toggler = menu.querySelector(".navbar-toggler-button");
		var collapsableMenu = menu.querySelector('.navbar-collapse-menu');

		toggler.addEventListener("click", function (e) {
			e.preventDefault();

			if (collapsableMenu.classList.contains('closed')) {
				collapsableMenu.classList.add('show-menu');
				$('body').css('overflow', 'hidden');
				collapsableMenu.classList.remove('closed');
				var menuIcon = toggler.querySelector('.iol-menu-icon');
				menuIcon.classList.remove('navbar-toggler-icon-open');
				menuIcon.classList.add('icon-close');
			}
			else {
				collapsableMenu.classList.add('closed');
				collapsableMenu.classList.remove('show-menu');
				$('body').css('overflow', 'auto');
				var menuIcon = toggler.querySelector('.iol-menu-icon');
				menuIcon.classList.add('navbar-toggler-icon-open');
				menuIcon.classList.remove('icon-close');
				var dropsownTogglers = collapsableMenu.querySelectorAll('.dropdown-toggle');
				dropsownTogglers.forEach(item => {
					var togglerIcon = item.querySelector('.icon');
					if (item.nextElementSibling.classList.contains('visible')) {
						togglerIcon.classList.add('icon-arrow-down');
						togglerIcon.classList.remove('icon-arrow-up');
						item.nextElementSibling.classList.remove('visible');
						item.classList.remove('visible');

					}
				});
				var searchBar = collapsableMenu.querySelector('.iol-search');
				searchBar.querySelector('.icon-full-search').classList.remove('visible');
			}

			var searchBar = collapsableMenu.querySelector('.iol-search');
			searchBar.addEventListener('focusin', function () {
				searchBar.querySelector('.icon-full-search').classList.add('visible');
				searchBar.querySelector('.iol-input').addEventListener('click', function () {
					$(this).focus();
					Keyboard.show();
				});
			});
			searchBar.addEventListener('focusout', function () {
				searchBar.querySelector('.icon-full-search').classList.remove('visible');
			});

			var dropsownTogglers = collapsableMenu.querySelectorAll('.dropdown-toggle');
			dropsownTogglers.forEach(item => {
				var togglerIcon = item.querySelector('.icon');
				item.addEventListener('click', function (e) {
					e.preventDefault();
					var dropMenu = item.parentElement.querySelector('.dropdown-menu');
					if (!dropMenu.classList.contains('visible')) {
						togglerIcon.classList.remove('icon-arrow-down');
						togglerIcon.classList.add('icon-arrow-up');
						dropMenu.classList.add('visible');
						item.classList.add('visible');

					}
					else {
						togglerIcon.classList.add('icon-arrow-down');
						togglerIcon.classList.remove('icon-arrow-up');
						dropMenu.classList.remove('visible')
						item.classList.remove('visible');
					}
				});
			});
		});
		if (window.matchMedia("(min-width: 1025px)").matches) {
			var collapsableSecond = menu.querySelector('.navbar-nav');
			var dropsownTogglers = collapsableSecond.querySelectorAll('.dropdown-toggle');

			dropsownTogglers.forEach(item => {
				var togglerIcon = item.querySelector('.icon');

				item.addEventListener('click', function () {
					if (item.classList.contains('show-dropdown')) {
						item.classList.remove('show-dropdown');
						$('body').css('overflow', 'auto');
						togglerIcon.classList.add('icon-arrow-down');
						togglerIcon.classList.remove('icon-arrow-up');
						item.nextElementSibling.classList.remove('visible');
					}
					else {
						dropsownTogglers.forEach(itemToClose => {
							var togglerIconToClose = itemToClose.querySelector('.icon');
							if (togglerIconToClose.classList.contains('icon-arrow-up')) {
								itemToClose.nextElementSibling.classList.remove("visible");
								itemToClose.classList.remove('show-dropdown');
								togglerIconToClose.classList.remove('icon-arrow-up');
								togglerIconToClose.classList.add('icon-arrow-down');
							}
						});

						item.classList.add('show-dropdown');
						$('body').css('overflow', 'hidden');
						togglerIcon.classList.remove('icon-arrow-down');
						togglerIcon.classList.add('icon-arrow-up');
						item.nextElementSibling.classList.add('visible');
					}
				});
			})
		}
	}
};

function pswShower() {
	var togglerPassword = document.querySelectorAll(".toggle-password");
	togglerPassword.forEach(item => {
		item.addEventListener('click', function () {
			$(this).toggleClass("icon-eye icon-eye-slash");
			var input = $(this).parent().find("input");
			if (input.attr("type") == "password") {
				input.attr("type", "text");
			} else {
				input.attr("type", "password");

			}
		})
	})
}

function openStickyPopup() {
	var stickybar = document.querySelector('.iol-stickybar');

	if (stickybar) {
		var toggler = stickybar.querySelector('.icon-arrow-down-sticky');
		var popup = stickybar.querySelector('.iol-popup');

		toggler.addEventListener('click', function () {
			popup.classList.toggle('iol-popup-open');
			toggler.classList.toggle('iol-popup-open');

		})
	}
}

function accordionOpener() {
	var accItems = document.querySelectorAll('.acc-inner');

	if (accItems) {
		accItems.forEach(function (accItem) {
			var accItemHeader = accItem.querySelector(".acc-inner__label");
			accItemHeader.addEventListener('click', function () {
				var accName = accItem.closest('.iol-accordion');
				if (jQuery(accItem).hasClass("acc-active")) {
					accItem.classList.remove("acc-active");
				} else {
					var allActive = accName.querySelectorAll('.acc-active');

					allActive.forEach(function (a) {
						a.classList.remove('acc-active');
					});

					accItem.classList.add('acc-active');
					accItem.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
				}
			});
		});
	}
}

function accordionfeaturesOpener() {
	var accItems = document.querySelectorAll('.acc-inner-ftable');

	if (accItems) {
		accItems.forEach(function (accItem) {
			var accItemHeader = accItem.querySelector(".acc-inner-ftable__label");
			accItemHeader.addEventListener('click', function () {
				var accName = accItem.closest('.table-slide');
				if (jQuery(accItem).hasClass("acc-active-ftable")) {
					accItem.classList.remove("acc-active-ftable");
				} else {
					var allActive = accName.querySelectorAll('.acc-active-ftable');

					allActive.forEach(function (a) {
						a.classList.remove('acc-active-ftable');
					});

					accItem.classList.add('acc-active-ftable');
					accName.scrollIntoView({ behavior: "smooth", block: "end", inline: "center" });
				}
			});
		});
	}
}

var autoslider = document.querySelector('.iol-auto-slider');
let isSliding = false;

function autoSlider() {
	if (isSliding) return;

	if (window.matchMedia('(min-width:1220px)').matches) {
		if (autoslider) {
			isSliding = true;

			var first = $(autoslider).find("ul").find("[data-tab='1']");
			first.addClass('current');
			$("#1").addClass('current');
			first.find(".placeholder").addClass('progress-bar_fill');
			first.find('.img-slide.no-mobile').addClass('current');
			first.find('.iol-block-content').addClass('current');

			function slider() {
				var block_id = $(autoslider).find('li.current').attr('data-tab');

				var block_id = $(autoslider).find('li.current').attr('data-tab');
				var n_block_id;
				if (parseInt(block_id) == ($(autoslider).find('li').length)) {
					n_block_id = 1;
				} else {
					n_block_id = parseInt(block_id) + 1;
				}

				var listElement = autoslider.querySelectorAll('ul.iol-blocks li');
				listElement.forEach(item => {
					$(item).removeClass('current');
					var blockContent = item.querySelector('.iol-block-content');
					var blockProgress = item.querySelector('.placeholder');
					var blockImg = item.querySelector('.img-slide.no-mobile');
					$(blockContent).removeClass('current');
					$(blockProgress).removeClass('progress-bar_fill');
					$(blockImg).removeClass('current');
				});

				var dataBlocksId = $(autoslider).find("ul").find("[data-tab='" + n_block_id + "']");
				dataBlocksId.addClass('current');
				$("#" + n_block_id).addClass('current');
				dataBlocksId.find(".placeholder").addClass('progress-bar_fill');
				dataBlocksId.find('.img-slide.no-mobile').addClass('current');
				dataBlocksId.find('.iol-block-content').addClass('current');
			}

			var timer = setInterval(slider, 9000);

			var toClick = autoslider.querySelectorAll('ul.iol-blocks li');

			toClick.forEach(itemToClick => {
				itemToClick.addEventListener('mouseover', function () {
					var block_id = $(this).attr('data-tab');
					clearInterval(timer);
					console.log(itemToClick);

					//timer = setInterval(slider, 6000);

					var listElement = autoslider.querySelectorAll('ul.iol-blocks li');
					listElement.forEach(item => {
						$(item).removeClass('current');
						var blockContent = item.querySelector('.iol-block-content');
						var blockProgress = item.querySelector('.placeholder');
						var blockImg = item.querySelector('.img-slide.no-mobile');
						$(blockContent).removeClass('current');
						$(blockProgress).removeClass('progress-bar_fill');
						$(blockImg).removeClass('current');
					});

					$(this).addClass('current');
					$("#" + block_id).addClass('current');
					$(this).find(".placeholder").addClass('progress-bar_fill');
					$(this).find('.img-slide.no-mobile').addClass('current');
					$(this).find('.iol-block-content').addClass('current');
				})

				itemToClick.addEventListener('mouseout', function () {
					timer = setInterval(slider, 9000);
				})
			})
		}
	} else {
		if (autoslider) {
			isSliding = true;

			var first = $(autoslider).find("ul").find("[data-tab='1']");
			first.addClass('current');
			$("#1").addClass('current');
			first.find(".placeholder").addClass('progress-bar_fill');
			first.find('.img-slide.no-desktop').addClass('current');
			first.find('.iol-block-content').addClass('current');

			function slider() {
				var block_id = $(autoslider).find('li.current').attr('data-tab');

				var block_id = $(autoslider).find('li.current').attr('data-tab');
				var n_block_id;
				if (parseInt(block_id) == ($(autoslider).find('li').length)) {
					n_block_id = 1;
				} else {
					n_block_id = parseInt(block_id) + 1;
				}

				var listElement = autoslider.querySelectorAll('ul.iol-blocks li');
				listElement.forEach(item => {
					$(item).removeClass('current');
					var blockContent = item.querySelector('.iol-block-content');
					var blockProgress = item.querySelector('.placeholder');
					var blockImg = item.querySelector('.img-slide.no-desktop');
					$(blockContent).removeClass('current');
					$(blockProgress).removeClass('progress-bar_fill');
					$(blockImg).removeClass('current');
				});

				var dataBlocksId = $(autoslider).find("ul").find("[data-tab='" + n_block_id + "']");
				dataBlocksId.addClass('current');
				$("#" + n_block_id).addClass('current');
				dataBlocksId.find(".placeholder").addClass('progress-bar_fill');
				dataBlocksId.find('.img-slide.no-desktop').addClass('current');
				dataBlocksId.find('.iol-block-content').addClass('current');
			}

			var timer = setInterval(slider, 9000);

			var toClick = autoslider.querySelectorAll('ul.iol-blocks li');

			toClick.forEach(itemToClick => {
				itemToClick.addEventListener('click', function () {
					var block_id = $(this).attr('data-tab');
					clearInterval(timer);

					timer = setInterval(slider, 9000);

					var listElement = autoslider.querySelectorAll('ul.iol-blocks li');
					listElement.forEach(item => {
						$(item).removeClass('current');
						var blockContent = item.querySelector('.iol-block-content');
						var blockProgress = item.querySelector('.placeholder');
						var blockImg = item.querySelector('.img-slide.no-desktop');
						$(blockContent).removeClass('current');
						$(blockProgress).removeClass('progress-bar_fill');
						$(blockImg).removeClass('current');
					});

					$(this).addClass('current');
					$("#" + block_id).addClass('current');
					$(this).find(".placeholder").addClass('progress-bar_fill');
					$(this).find('.img-slide.no-desktop').addClass('current');
					$(this).find('.iol-block-content').addClass('current');

				})
			})
		}
	}



}

if (autoslider) {
	let options = {
		rootMargin: "400px",
		threshold: 1.0
	};

	let observer = new IntersectionObserver((entries) => {
		const entry = entries[0];
		if (entry.isIntersecting) {
			autoSlider();
		}
	}, options);

	observer.observe(autoslider);
}

function statsLabelHeight() {
	var statistics = document.querySelectorAll(".iol-statistics");
	var arrayHeight = [];

	setTimeout(function () {
		if (statistics) {
			$(statistics).each(function () {
				var fieldTexts = document.querySelectorAll('.iol-statistic .iol-stat-label');
				fieldTexts.forEach(function (field, i) {
					arrayHeight[i] = field.clientHeight;
				});
				fieldTexts.forEach(function (field) {
					$(field).css("min-height", Math.max.apply(Math, arrayHeight));
				});
			});
		}
	}, 500);
}

function initializeTooltips() {
	if (typeof bootstrap === 'undefined') {
		console.warn("Bootstrap non è caricato, impossibile inizializzare i tooltip.");
		return;
	}

	const tooltipElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');

	// Rileva se il dispositivo è mobile
	const isTouchDevice = navigator.maxTouchPoints > 0 || 'ontouchstart' in document.documentElement;
	const triggerType = isTouchDevice ? 'manual' : 'hover';

	// Inizializza i tooltip
	tooltipElements.forEach(tooltipEl => {
		const tooltipInstance = new bootstrap.Tooltip(tooltipEl, { trigger: triggerType });

		if (isTouchDevice) {
			tooltipEl.addEventListener("click", function (event) {
				event.stopPropagation(); // Impedisce che il click si propaghi e chiuda immediatamente il tooltip

				// Controlla se il tooltip è già aperto e lo chiude, altrimenti lo apre
				if (tooltipInstance.tip && tooltipInstance.tip.classList.contains("show")) {
					tooltipInstance.hide();
				} else {
					closeAllTooltips(); // Chiude eventuali altri tooltip aperti
					tooltipInstance.show();
				}
			});
		}
	});

	// Se il dispositivo è touch, chiude il tooltip quando si clicca fuori
	if (isTouchDevice) {
		document.addEventListener("click", function (event) {
			if (!event.target.matches('[data-bs-toggle="tooltip"]')) {
				closeAllTooltips();
			}
		});
	}
}

// Chiude tutti i tooltip aperti
function closeAllTooltips() {
	const tooltipElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');
	tooltipElements.forEach(tooltipEl => {
		const tooltipInstance = bootstrap.Tooltip.getInstance(tooltipEl);
		if (tooltipInstance) tooltipInstance.hide();
	});
}

